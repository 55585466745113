<template>
  <div id="Recruitments">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <v-col>
              <v-row>
                <v-col cols="4" md="4" style="margin-top: 10px">
                  <hotel-date-picker
                    style="opacity: 90%; z-index: 2"
                    :halfDay="true"
                    :displayClearButton="true"
                    :i18n="frFR"
                    :positionRight="false"
                    :minNights="1"
                    :format="dateFormat"
                    :showYear="true"
                    :showCloseButton="false"
                    :startDate="new Date(new Date().getFullYear(), 0, 1)"
                    :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                    @check-in-changed="checkInChanged($event)"
                    @check-out-changed="checkOutChanged($event)"
                    @clear-selection="clear($event)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-card>
              <v-card-title> Candidatures </v-card-title>
              <v-data-table
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, 30],
                }"
                :headers="headers"
                :items="recruitments"
                :search="search"
                :server-items-length="totalRecruitmentsCount"
                :options.sync="options"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:item.name="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.name }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:item.surname="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.surname }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.surname }}
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.email }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.email }}
                  </div>
                </template>
                <template v-slot:item.phoneNumber="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.phoneNumber }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.phoneNumber }}
                  </div>
                </template>
                <template v-slot:item.date="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.date }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.date }}
                  </div>
                </template>
                <template v-slot:item.level="{ item }">
                  <div v-if="item.read == true" shaped single-line tile>
                    {{ item.level }}
                  </div>

                  <div
                    v-if="item.read == false"
                    shaped
                    single-line
                    tile
                    class="font-weight-black"
                  >
                    {{ item.level }}
                  </div>
                </template>
                <template #item.curriculumvitae="{ value, item }">
                  <a
                    target="_blank"
                    :href="value"
                    @click="onReadCV($event, value, item)"
                  >
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </a>
                </template>

                <template #item.coverletter="{ value, item }">
                  <a
                    target="_blank"
                    :href="value"
                    @click="onReadLetter($event, value, item)"
                  >
                    <v-icon> mdi-file-pdf-box </v-icon>
                  </a>
                </template>
                <template v-slot:item.rate="{ item }">
                  <v-rating
                    v-model="item.rate"
                    background-color="grey"
                    color="warning"
                    dense
                    half-increments
                    hover
                    size="20"
                    @input="onRateClicked($event, item)"
                  ></v-rating>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import FirebaseDB from "../../utils/firebaseDB";
import nodesFieldName from "../../utils/nodesFieldName";
import moment from "moment";
import FunctionsUtils from "./../../utils/functions";
import HotelDatePicker from "vue-hotel-datepicker2";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";

export default {
  name: "Recruitments",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    HotelDatePicker,
  },

  data() {
    return {
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date de candidature",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],

        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      fromDate: new Date("2021-01-01"),
      toDate: new Date(Date.now() + 3600 * 1000 * 24),
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      menu: false,
      modalTo: false,
      modalFrom: false,
      recruitments: [],
      totalRecruitments: [],
      search: "",
      options: {},
      name: null,
      surname: null,
      phoneNumber: null,
      email: null,
      curriculumvitae: null,
      coverletter: null,
      totalRecruitmentsCount: 0,
      loading: true,
      lastVisibleRecruitments: null,
      userId: null,
      date: null,
      dates: new Date().toISOString().substr(0, 10),
      menuCalendar: false,
      from: null,
      to: null,
      read: null,
      level: null,
      length: 5,
      rate: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next((vm) => (vm.loading = false));
  },
  methods: {
    onReadCV(event, value, item) {
      if (!item.read) {
        var Recruitments = localStorage.getItem("NbrRecruitments");
        var NbrRecruitments = parseInt(Recruitments) - 1;
        localStorage.setItem("NbrRecruitments", NbrRecruitments);
        item.read = true;
      }

      db.collection(NodesName.RECRUITMENTS)
        .where("curriculumVitae", "==", value)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref.update({
              read: true,
            });
          });
        });
    },

    onReadLetter(event, value, item) {
      if (!item.read) {
        var Recruitments = localStorage.getItem("NbrRecruitments");
        var NbrRecruitments = parseInt(Recruitments) - 1;
        localStorage.setItem("NbrRecruitments", NbrRecruitments);
        item.read = true;
      }

      db.collection(NodesName.RECRUITMENTS)
        .where("coverLetter", "==", value)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref.update({
              read: true,
            });
          });
        });
    },
    clear() {
      this.fromDate = new Date("2021-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.totalRecruitments = [];
      this.recruitments = [];
      this.lastVisibleRecruitments = null;
    },
    checkInChanged(newDate) {
      this.totalRecruitments = [];
      this.recruitments = [];
      this.lastVisibleRecruitments = null;
      this.fromDate = newDate;
      this.loadRecruitments();
    },
    checkOutChanged(newDate) {
      this.totalRecruitments = [];
      this.recruitments = [];
      this.lastVisibleRecruitments = null;
      this.toDate = newDate;
      this.loadRecruitments();
    },
    onRateClicked(event, item) {
      db.collection(NodesName.RECRUITMENTS)
        .doc(item.document_id)
        .update({
          rating: item.rate,
        })
        .catch(function (error) {
          console.error("Error updating document: ", error);
        });
    },

    async loadRecruitments() {
      this.loading = true;

      if (!this.fromDate && !this.toDate) {
        this.fromDate = new Date("2021-01-01");
        this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = null;

      query = db
        .collection(NodesName.RECRUITMENTS)
        .where(nodesFieldName.CREATION_DATE, ">=", this.fromDate)
        .where(nodesFieldName.CREATION_DATE, "<=", this.toDate);

      var generalStats = await FirebaseDB.getGeneralStats();
      generalStats.forEach((stat) => {
        this.totalRecruitmentsCount = stat.data().number_total_recruitments;
      });

      if (this.totalRecruitments.length < this.totalRecruitmentsCount) {
        var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
          query,
          this.lastVisibleRecruitments,
          itemsPerPage,
          this.totalRecruitmentsCount
        );

        let i = 0;
        querySnapshot.forEach((recruitment) => {
          if (Object.entries(recruitment.data()).length !== 0) {
            const found = this.totalRecruitments.some(
              (el) => el.recruitment_id === recruitment.uniqueId
            );
            if (!found) {
              this.totalRecruitments.push({
                document_id: recruitment.id,
                name: recruitment.data().name,
                surname: recruitment.data().surname,
                recruitment_id: recruitment.data().uniqueId,
                email: recruitment.data().email,
                phoneNumber: recruitment.data().phone,
                curriculumvitae: recruitment.data().curriculumVitae,
                coverletter: recruitment.data().coverLetter,
                read: recruitment.data().read,
                level: recruitment.data().level,
                date: moment(
                  new Date(recruitment.data().creationDate.seconds * 1000)
                ).format("L HH:mm:ss"),
                rate: recruitment.data().rating,
              });
              i++;

              if (querySnapshot.size == i) {
                this.lastVisibleRecruitments = recruitment;
              }
            }
          }
        });
      }

      this.recruitments = FunctionsUtils.customSortDataTable(
        this.totalRecruitments,
        sortBy,
        sortDesc
      );

      this.recruitments = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalRecruitments,
        itemsPerPage,
        page,
        this.totalRecruitmentsCount
      );

      this.loading = false;
    },
  },
  computed: {
    dateFormat() {
      return "MM/DD/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Surname", value: "surname" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phoneNumber" },
        { text: "Date de Candidature", value: "date" },
        { text: "Niveau d'étude", value: "level" },
        { text: "Curriculum Vitae", value: "curriculumvitae" },
        { text: "Lettre de motivation", value: "coverletter" },
        { text: "Note de candidature", value: "rate" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.loadRecruitments();
      },
      deep: true,
    },
  },
};
</script>
